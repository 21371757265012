<template>
  <div :class="isMobile ? 'dashboard-mobile' : 'dashboard'">
    <!-- side bar -->
    <div v-if="!isMobile" class="dashboard__sidebar">
      <CustomMenu in-dashboard />
    </div>
    <DashboardNavbar
      @menuClick="setMenuVisibility(true)"
      @setLoading="setLoading"
      @setChange="setHeadMasterCurrentSchool"
    />
    <LoaderCircular :show="!loaded" :size="60" :width="8" class="loader" />
    <div v-if="loaded" class="widgets">
      <!-- eslint-disable-next-line vuejs-accessibility/iframe-has-title -->
      <iframe
        v-for="widget in widgets"
        :key="widget.link"
        height="auto"
        width="auto"
        class="iframe"
        :style="{ '--grid-area': widget.gridArea }"
        :src="widget.link"
        loading="lazy"
      />
    </div>
  </div>
</template>

<script>
import LoaderCircular from '@/components/atoms/loaders/LoaderCircular.vue';
import DashboardNavbar from '@/components/organisms/dashboard/DashboardNavbar.vue';
import { mapActions, mapGetters } from 'vuex';
import CONFIG from '@/config';
import CustomMenu from '../../layouts/CustomMenu.vue';

export default {
  name: 'AnalyticsInstagram',
  components: {
    CustomMenu,
    DashboardNavbar,
    LoaderCircular,
  },
  data() {
    return {
      loaded: true,
    };
  },
  computed: {
    ...mapGetters({
      currentHeadMasterSchools: 'roles/currentHeadMasterSchools',
      campusDetail: 'roles/currentHeadMasterMainSchool',
    }),
    isMobile() {
      return this.$vuetify.breakpoint.smAndDown;
    },
    schools() {
      if (!this.currentHeadMasterSchools) {
        return [];
      }
      return this.currentHeadMasterSchools.map((item, index) => ({
        label: `${item.campus_name}`,
        campus_uuid: item.uuid,
        value: index,
      }));
    },
    widgets() {
      const widgetsArray = [
        { link: `${CONFIG.staticWidgetsBaseURL}/Dashboard_SimpleInformer?campus_code=${this.campusDetail.campus_code}&__s=0`, gridArea: 'widget1' },
        { link: `${CONFIG.staticWidgetsBaseURL}/Dashboard_SimpleInformer?campus_code=${this.campusDetail.campus_code}&__s=3`, gridArea: 'widget2' },
        { link: `${CONFIG.staticWidgetsBaseURL}/Dashboard_SimpleInformer?campus_code=${this.campusDetail.campus_code}&__s=1`, gridArea: 'widget3' },
        { link: `${CONFIG.staticWidgetsBaseURL}/Dashboard_SimpleInformer?campus_code=${this.campusDetail.campus_code}&__s=2`, gridArea: 'widget4' },
        { link: `${CONFIG.staticWidgetsBaseURL}/Dashboard_GlobalStatistics?campus_code=${this.campusDetail.campus_code}`, gridArea: 'widget5' },
        { link: `${CONFIG.staticWidgetsBaseURL}/Dashboard_BarGraph?campus_code=${this.campusDetail.campus_code}&__s=0`, gridArea: 'widget6' },
        { link: `${CONFIG.staticWidgetsBaseURL}/InstagramData?campus_code=${this.campusDetail.campus_code}`, gridArea: 'widget7' },
        { link: `${CONFIG.staticWidgetsBaseURL}/Dashboard_BlockTable?campus_code=${this.campusDetail.campus_code}&__s=0`, gridArea: 'widget8' },
      ];
      return widgetsArray;
    },
  },
  methods: {
    ...mapActions({
      setHeadMasterMainSchool: 'roles/setHeadMasterMainSchool',
      setHeadMasterFirstLogin: 'roles/setHeadMasterFirstLogin',
      retrieveCampusDetails: 'institutions/retrieveCampusDetails',
    }),
    setHeadMasterCurrentSchool(index) {
      if (index !== null) {
        this.loaded = false;
        const campusUUID = this.schools[index].campus_uuid;
        if (!('id' in this.currentHeadMasterSchools[index])) {
          this.retrieveCampusDetails({ searchCode: campusUUID }).then((campusDetails) => {
            this.currentHeadMasterSchools[index] = campusDetails;
            this.setHeadMasterMainSchool({ mainSchool: this.currentHeadMasterSchools[index] }).then(
              () => {
                this.loaded = true;
              },
            );
          });
        } else {
          this.setHeadMasterMainSchool({ mainSchool: this.currentHeadMasterSchools[index] }).then(
            () => {
              this.loaded = true;
            },
          );
        }
      }
    },
    setLoading(bool) {
      this.loaded = bool;
    },
  },
};
</script>
<style lang="scss" scoped>
.container-analytics {
  width: 100%;
  height: 100vh; /* Set the height to 100 viewport height */
  padding-top: 2rem;
  position: relative;
}
.widgets {
  margin-left: 10px;
  margin-right: 10px;
  display: grid;
  grid-column: sidebar-end / end;
  grid-row: header-end / end;
  overflow-y: overlay;
  display: grid;
  grid-template-columns: [start] 16.6% [first] 16.6% [second] 16.6% [third] 16.6% [fourth] 16.6% [end];
  grid-template-rows: repeat(6, 25%);
  grid-template-areas:
    'widget1 widget2 widget3 widget4 widget7 widget7'
    'widget5 widget5 widget8 widget8 widget7 widget7'
    'widget5 widget5 widget8 widget8 widget7 widget7'
    'widget6 widget6 widget6 widget6 widget7 widget7'
    'widget6 widget6 widget6 widget6 . .'
    'widget6 widget6 widget6 widget6 . .';

    grid-gap: 20px; /* Adjust the gap between widgets */
    border-radius: 10px;
}

.iframe {
  background-color: #FAFAFA !important;
  grid-area: var(--grid-area);
  width: 100%;
  height: 100%;
}

.loader{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
</style>
